<template>
  <div class="wrap">
    <van-nav-bar :fixed="true" left-arrow placeholder @click-left="$router.go(-1)"
      :title="$t('newAdd.AddWalletTitle')" />
    <div class="tab">
      <div class="tab_nav" @click="navActive = 0" :class="navActive == 0 ? 'active' : ''">
        <span>{{ $t('newBank.usdt.wallet') }}</span>
      </div>
      <div class="tab_nav" @click="navActive = 1" :class="navActive == 1 ? 'active' : ''">
        <span>{{ $t('newAdd.BankCard') }}</span>
      </div>
    </div>
    <div class="content_wrap">
      <template v-if="navActive == 0">
        <template>
          <div class="title">{{ $t("newBank.usdt.name") }}</div>
          <input type="text" v-model="formdata.name" />
        </template>
        <template>
          <div class="title">{{ $t("newBank.usdt.currency") }}</div>
          <div class="options">
            <div class="option_item" v-for="(item, index) in currencyTypes" :key="`currency_${index}`"
              :class="formdata.bank == item.value && 'active'" @click="$set(formdata, 'bank', item.value)">
              {{ item.title }}
            </div>
          </div>
        </template>
        <template>
          <div class="title">{{ $t("newBank.usdt.chainType") }}</div>
          <div class="options">
            <div class="option_item" v-for="(item, index) in chainTypes" :key="`chainType_${index}`"
              :class="formdata.open == item.value && 'active'" @click="$set(formdata, 'open', item.value)">
              {{ item.title }}
            </div>
          </div>
          <div class="title">{{ $t("newBank.usdt.depositAddress") }}</div>
          <!-- <input type="text" v-model="formdata.card" /> -->
          <textarea cols="30" rows="10" v-model.trim="formdata.card"></textarea>
        </template>
      </template>
      <template v-else>
        <div class="title">{{ $t("newBank.usdt.name") }}</div>
        <input type="text" v-model="bankData.name" />
        <div class="title">{{ $t("newBank.usdt.bankName") }}</div>
        <input type="text" v-model="bankData.bank" />
        <div class="title">{{ $t("newBank.usdt.bankCard") }}</div>
        <input type="text" v-model="bankData.card" />
      </template>
      <div class="submit" @click="submit">{{ $t("newBank.usdt.submit") }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { NavBar, Toast } from "vant";
import { COMMIT_USER_BANK_INFO } from "../../api";
Vue.use(NavBar);
export default {
  data: () => ({
    currencyTypes: [
      { title: "USDT", value: "USDT" },
      // { title: "BTC", value: "BTC" },
    ],
    chainTypes: [
      { title: "TRC20", value: "TRC20" },
      { title: "ERC20", value: "ERC20" },
      { title: "BEP20", value: "BEP20" },
    ],
    formdata: {},
    bankData: {},
    loading: false,
    navActive: 0,
  }),
  created() {
    const data = this.$route.query.data;
    const formdata = data ? JSON.parse(data) : {};
    if (formdata.open != '') {
      this.navActive = 0
      this.formdata = formdata
    } else {
      this.navActive = 1;
      this.bankData = formdata
    }
  },
  methods: {
    async submit() {
      Toast.loading();
      this.loading = true;
      const params = this.navActive == 0 ? {...this.formdata} : {...this.bankData,open:""}
      try {
        const {
          data: { ret, msg, data },
        } = await COMMIT_USER_BANK_INFO(params);
        Toast.clear();
        this.loading = false;
        Toast(msg);
        if (ret == 1) {
          // this.$cookie.set("selectBankInfo", JSON.stringify(data));
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        }
      } catch (error) {
        Toast.clear();
        this.loading = false;
        console.error(`提交上传银行卡错误: `, error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.wrap {
  @theme: #f0ebef;
  background: @background;
  min-height: 100vh;

  .tab {
    background-color: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;

    .tab_nav {
      padding: 0 calc(30rem / 16);
      color: var(--sizeColor);
      font-size: calc(14rem / 16);
    }

    .active {
      color: var(--theme);

      span {
        height: 44px;
        line-height: 44px;
        display: inline-block;
        border-bottom: 2px solid var(--theme);
      }
    }
  }

  .content_wrap {
    @paddingHor: calc(10rem / 16);
    margin: calc(18rem / 16) calc(12rem / 16);
    // background: #fff;
    background: var(--bg);
    padding: calc(12rem / 16) calc(10rem / 16);
    border-radius: calc(8rem / 16);

    >.title {
      font-size: calc(14rem / 16);
      margin-top: calc(26rem / 16);
      color: var(--sizeColor);

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    >input {
      // background: @theme;
      background: @background;
      width: calc(100% - @paddingHor);
      border: none;
      border-radius: calc(4rem / 16);
      height: calc(36rem / 16);
      line-height: calc(36rem / 16);
      text-indent: calc(12rem / 16);
      margin-top: calc(6rem / 16);
      color: @Color;
    }

    >textarea {
      // background: @theme;
      background: @background;
      color: @Color;
      width: calc(100% - @paddingHor - calc(20rem / 16));
      border: none;
      border-radius: calc(4rem / 16);
      height: calc(80rem / 16);
      // line-height: calc(36rem / 16);
      // text-indent: calc(12rem / 16);
      padding: calc(12rem / 16);
      margin-top: calc(6rem / 16);
    }

    .options {
      margin-top: calc(8rem / 16);
      display: flex;

      .option_item {
        padding: 0 calc(10rem / 16);
        line-height: calc(32rem / 16);
        border-radius: 99px;
        // background: @theme;
        background: @background;
        color: @Color;
        min-width: calc(60rem / 16);
        text-align: center;
        font-size: calc(14rem / 16);
        margin-left: calc(20rem / 16);

        &:nth-child(1) {
          margin-left: 0;
        }
      }

      .active {
        background: var(--newBtn);
        color: var(--btnsizeColor);
      }
    }

    .submit {
      font-size: calc(16rem / 16);
      // background: var(--theme);
      // background: @background;
      background-color: var(--newBtn);
      // color: @Color;
      color: var(--btnsizeColor);
      // color: #fff;
      text-align: center;
      line-height: calc(40rem / 16);
      width: calc(100% - @paddingHor + 4rem / 16);
      margin: calc(60rem / 16) 0;
      border-radius: calc(4rem / 16);

      // margin: calc(50rem / 16) auto 0;
      &:active {
        opacity: 0.7;
      }
    }
  }
}
</style>